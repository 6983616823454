<template>
  <div id="calendarPage">
    <EventCalendar></EventCalendar>
  </div>
</template>

<script>
import EventCalendar from "@/components/EventCalendar"

export default {
  name: "Calendar.vue",
  components: { EventCalendar }
}
</script>

<style scoped>
#calendarPage {
  padding: 1.5em;
}
</style>
